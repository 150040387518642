<template>
  <div>
    <MainNavigation></MainNavigation>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainNavigation from '@/components/MainNavigation.vue'
import Footer from '@/components/Footer.vue'
import AuthenticationService from './services/AuthenticationService'
export default {
  name: "App",
  computed: {
    ...mapState(['loading']),
  },
  components: {
    MainNavigation,
    Footer,
  },
  mounted () {
    // this.sendLogin(); // sample login request pattern
  },
  data() {
    return {}
  },
  methods: {
    sendLogin() {
      const payload = {
        email: "engchris95@gmail.com",
        password: "secret1234"
      }
      this.$store.dispatch("loading", true)
      AuthenticationService.login(payload).then((result) => {
        this.showAlert("Success", "User logged in successful", "success")
      }).catch((err) => {
        if (err.response === undefined) {
          this.showAlert("Error occured", "Oops! took long to get a response", "warning")
        } else {
          this.showAlert("Error occured", "Oops! took long to get a response", "warning")
        }
      }).finally(() => {
        this.$store.dispatch("loading", false)
      });
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      })
    }
  },
};
</script>

<style>
.swal2-popup {
  font-size: 0.7rem !important;
}
</style>
