<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <div class="container">
        <router-link to="/" class="navbar-brand">
          <img
            src="assets/img/asolarlogo.png"
            class="navbar-brand-img"
            alt="Asolar logo"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapse">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fe fe-x"></i>
          </button>

          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/hybrid-system" class="nav-link">Hybrid System</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link">Contact us</router-link>
            </li>
            <li class="nav-item">
              <a href="https://asolarnig.com" target="_blank" class="nav-link">Main website <i class="fe fe-external-link pe-1"></i></a>
            </li>
          </ul>

          <!-- Button -->
          <a class="navbar-btn btn btn-sm btn-primary lift ms-auto" href="#interestSection" v-if="currentRoute == 'Home'">
            Show interest <i class="fe fe-arrow-down ms-3"></i>
          </a>

        </div>
      </div>
    </nav>
</template>
<script>
export default {
  name: "MainNavigation",
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #fece01;
}
.navbar-light .navbar-nav .nav-link {
  color: #008757;
}
</style>