<template>
  <!-- FAQ -->
  <section class="pb-10 bg-dark">
    <div class="container pt-8 pt-md-11">
      <div class="row justify-content-center mb-5">
        <div class="col-12 col-md-10 col-lg-8 text-center">
          <!-- Badge -->
          <span class="badge rounded-pill bg-gray-700-soft mb-4">
            <span class="h6 fw-bold text-uppercase">Get started</span>
          </span>
          <!-- Heading -->
          <h1 class="display-4 text-white">Frequently Asked Questions</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <!-- Item -->
          <div class="d-flex">
            <!-- Badge -->
            <div class="badge badge-lg badge-rounded-circle bg-success">
              <span>?</span>
            </div>

            <div class="ms-5">
              <!-- Heading -->
              <h4 class="text-white">How can I get a payment plan?</h4>

              <!-- Text -->
              <p class="text-muted mb-6 mb-md-8">
                We offer two payment plans:
                <strong>Outright payment</strong> and
                <strong>Pay as you go (PAYG) payment</strong>.
              </p>
            </div>
          </div>

          <!-- Item -->
          <div class="d-flex">
            <!-- Badge -->
            <div class="badge badge-lg badge-rounded-circle bg-success">
              <span>?</span>
            </div>

            <div class="ms-5">
              <!-- Heading -->
              <h4 class="text-white">What is the price of solar units?</h4>

              <!-- Text -->
              <p class="text-muted mb-6 mb-md-0">
                We offer a variety of affordable products ranging from Solar
                lighting Systems to Solar TV and Fan Systems which all come at a
                negotiable Price range
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <!-- Item -->
          <div class="d-flex">
            <!-- Badge -->
            <div class="badge badge-lg badge-rounded-circle bg-success">
              <span>?</span>
            </div>

            <div class="ms-5">
              <!-- Heading -->
              <h4 class="text-white">What kind of payment do you accept?</h4>

              <!-- Text -->
              <p class="text-muted mb-6 mb-md-8">
                We accept <strong>Bank Deposit</strong> and
                <strong>Bank Transfers</strong>, also payment via our agents
                around your locality
              </p>
            </div>
          </div>

          <!-- Item -->
          <div class="d-flex">
            <!-- Badge -->
            <div class="badge badge-lg badge-rounded-circle bg-success">
              <span>?</span>
            </div>

            <div class="ms-5">
              <!-- Heading -->
              <h4 class="text-white">Do you offer support?</h4>

              <!-- Text -->
              <p class="text-muted mb-6 mb-md-8">
                Yes, We offer 2-3 years warranty services <br />
                Our customer care helpline runs from 8am to 6pm (Monday to
                Friday) and 10am to 2pm on Saturday
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <!-- Item -->
          <div class="d-flex">
            <!-- Badge -->
            <div class="badge badge-lg badge-rounded-circle bg-success">
              <span>?</span>
            </div>

            <div class="ms-5">
              <!-- Heading -->
              <h4 class="text-white">Do you offer quality products?</h4>

              <!-- Text -->
              <p class="text-muted mb-6 mb-md-0">
                Our Products ranges from small lighting Home Systems to TV
                Systems
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
</template>
<script>
export default {
  name: "FAQ",
};
</script>
<style scoped>
</style>