<template>
  <!-- FOOTER -->
  <footer class="py-8 py-md-6 bg-gray-200">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3">
          <!-- Brand -->
          <img
            src="assets/img/asolarlogo.png"
            alt="..."
            class="footer-brand img-fluid mb-2"
          />

          <!-- Text -->
          <p class="text-gray-700 mb-2">Plug in the sun.</p>

          <p class="text-gray-700 mb-2" style="font-size: 13px">
            <a href="tel:2349070362210">+(234) 907 036 2210</a><br />
            <a href="tel:2349070362220">+(234) 907 036 2220</a>
          </p>

          <!-- Social -->
          <ul class="list-unstyled list-inline list-social mb-6 mb-md-0">
            <li class="list-inline-item list-social-item me-3">
              <a href="#!" class="text-decoration-none">
                <img
                  src="assets/img/icons/social/instagram.svg"
                  class="list-social-icon"
                  alt="..."
                />
              </a>
            </li>
            <li class="list-inline-item list-social-item me-3">
              <a href="#!" class="text-decoration-none">
                <img
                  src="assets/img/icons/social/facebook.svg"
                  class="list-social-icon"
                  alt="..."
                />
              </a>
            </li>
            <li class="list-inline-item list-social-item me-3">
              <a href="#!" class="text-decoration-none">
                <img
                  src="assets/img/icons/social/twitter.svg"
                  class="list-social-icon"
                  alt="..."
                />
              </a>
            </li>
            <li class="list-inline-item list-social-item">
              <a href="#!" class="text-decoration-none">
                <img
                  src="assets/img/icons/social/pinterest.svg"
                  class="list-social-icon"
                  alt="..."
                />
              </a>
            </li>
          </ul>

          <!-- Text -->
          <p class="text-gray-700 mt-2">&copy; Copyright 2021</p>
        </div>
        <!-- <div class="col-6 col-md-3 offset-md-3">
          <h6 class="fw-bold text-uppercase text-gray-700">Connect</h6>

          <ul class="list-unstyled text-muted mb-0">
            <li class="mb-3">
              <a href="#!" class="text-reset"> Page Builder </a>
            </li>
            <li class="mb-3">
              <a href="#!" class="text-reset"> UI Kit </a>
            </li>
            <li class="mb-3">
              <a href="#!" class="text-reset"> Styleguide </a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3">
          <h6 class="fw-bold text-uppercase text-gray-700">Legal</h6>

          <ul class="list-unstyled text-muted mb-0">
            <li class="mb-3">
              <a href="#!" class="text-reset"> Documentation </a>
            </li>
            <li class="mb-3">
              <a href="#!" class="text-reset"> Changelog </a>
            </li>
            <li>
              <a href="#!" class="text-reset"> Pagebuilder </a>
            </li>
          </ul>
        </div> -->
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
