<template>
  <div>
    <!-- WELCOME -->
    <section class="pt-4 pt-md-11 bg-colored">
      <div class="container">
        <div class="row align-items-center" style="padding: 15% 0px">
          <div class="col-12 col-md-5 col-lg-6 order-md-2">

            <!-- Image -->
            <img src="assets/img/slider-design.gif" class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" alt="Asolar product image" data-aos="fade-up" data-aos-delay="100">

          </div>
          <div class="col-12 col-md-7 col-lg-6 order-md-1" data-aos="fade-up">

            <!-- Heading -->
            <h1 class="display-3 text-center text-md-start">
              Welcome to <span class="text-primary">Asolar</span>. <br>
              Plug in the Sun.
            </h1>

            <!-- Text -->
            <p class="lead text-center text-md-start text-muted mb-6 mb-lg-8 text-white">
              We are a leading national solar home systems and services provider company.
            </p>

            <!-- Buttons -->
            <div class="text-center text-md-start">
              <a href="#interestSection" class="btn btn-primary me-1 lift">
                Get started <i class="fe fe-arrow-down ms-3"></i>
              </a>
            </div>

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    </section>
    <!-- FEATURES -->
    <section class="py-8 py-md-11 border-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4" data-aos="fade-up">
            <!-- Icon -->
            <div class="icon text-primary mb-3">
              <img
                src="assets/img/battery.svg"
                alt="Asolar grids"
                width="48px"
              />
              <!-- <div>Icons made by <a href="" title="phatplus">phatplus</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> -->
            </div>

            <!-- Heading -->
            <h3>Beyond the Grid</h3>

            <!-- Text -->
            <p class="text-muted mb-6 mb-md-0">
              We are in the most rural and remote parts of Nigeria
            </p>
          </div>
          <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="50">
            <!-- Icon -->
            <div class="icon text-primary mb-3">
              <img
                src="assets/img/family.svg"
                alt="Asolar cares"
                width="48px"
              />
              <!-- <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> -->
            </div>

            <!-- Heading -->
            <h3>We care about you</h3>

            <!-- Text -->
            <p class="text-muted mb-6 mb-md-0">
              We don't just sell, we educate you on our product
            </p>
          </div>
          <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="100">
            <!-- Icon -->
            <div class="icon text-primary mb-3">
              <img
                src="assets/img/support.svg"
                width="48px"
                alt="Asolar Support"
              />
            </div>

            <!-- Heading -->
            <h3>Our customer service</h3>

            <!-- Text -->
            <p class="text-muted mb-0">
              We provide you with excellent customer experience even in your
              language
            </p>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <Brands />

    <section class="pt-8 pt-md-11 bg-gradient-light-white" id="interestSection">
      <div class="container">
        <div
          class="row align-items-center justify-content-between mb-8 mb-md-11"
        >
          <div class="col-12 col-md-5 order-md-1" data-aos="fade-left">
            <h2>
              We will definitely reachout <br />
              <span class="text-success"
                >because we care about you
                <span
                  data-typed='{"strings": ["developers.", "founders.", "designers."]}'
                ></span
              ></span>
            </h2>
            <!-- Text -->
            <p class="fs-sm text-muted mb-6" v-show="!package_image">
              ASOLAR was founded in 2016 to partake in an initiative to bring
              power to off-grid communities Our products and services are
              fundamentally customer-focused. Home energy systems to commercial
              projects. Cost optimization, minimize risk and provide
              comprehensive support before and after sales.
            </p>
            <div class="text-center" v-show="package_image">
              <img :src="package_image" alt="Package" class="img-fluid mx-auto d-block">
            </div>
          </div>
          <div class="col-12 col-md-7 col-lg-7" data-aos="fade-right">
            <!-- Card -->
            <div class="card shadow-light-lg lift lift-lg">
              <!-- Body -->
              <div class="card-body">
                <!-- Form -->
                <form @submit.prevent="sendRequest()" v-if="!success">
                  <div class="row justify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control form-control-flush"
                          id="cardName"
                          placeholder="Christopher"
                          v-model="fields.fullname"
                        />
                        <label for="cardName"
                          >Fullname <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control form-control-flush"
                          id="cardPhone"
                          placeholder="Phone"
                          v-model="fields.phone"
                        />
                        <label for="cardPhone"
                          >Phone <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control form-control-flush"
                          id="cardEmail"
                          placeholder="Email"
                          v-model="fields.email"
                        />
                        <label for="cardEmail">Email</label>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-floating">
                        <select
                          name=""
                          id="payment_plan"
                          class="form-control form-control-flush"
                          v-model="fields.payment_plan"
                          @change="fields.system = ''"
                        >
                          <option value="" disabled>Select an option</option>
                          <option value="oneoff">One off</option>
                          <option value="paygo">Pay as you go</option>
                        </select>
                        <label for="payment_plan"
                          >Select payment plan
                          <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row jusstify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <select
                          name=""
                          id="cardType"
                          class="form-control form-control-flush"
                          v-model="fields.system"
                        >
                          <option value="">Select an option</option>
                          <option value="Basic System" v-if="fields.payment_plan === 'paygo'">Basic System</option>
                          <option value="Basic System - One Off" v-if="fields.payment_plan === 'oneoff'">Basic System - One Off</option>
                          <option value="Fan System" v-if="fields.payment_plan === 'paygo'">Fan System</option>
                          <option value="Fan System - One Off" v-if="fields.payment_plan === 'oneoff'">Fan System - One Off</option>
                          <option value="TV System" v-if="fields.payment_plan === 'paygo'" disabled>TV System (Out of Stock)</option>
                          <option value="TV System - One Off" v-if="fields.payment_plan === 'oneoff'" disabled>TV System - One Off (Out of Stock)</option>
                        </select>
                        <label for="cardSystem"
                          >Select system
                          <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center" v-if="fields.system !== ''">
                    <div class="col">
                      <div class="alert alert-success text-center">
                        <small v-if="fields.system == 'Basic System'"><span class="double-line-through">N</span>3,500 initial payment, <span class="double-line-through">N</span>900 weekly repayment plan <strong>(<span class="double-line-through">N</span>85,000)</strong></small>
                        <small v-if="fields.system == 'Fan System'"><span class="double-line-through">N</span>10,000 initial payment, <span class="double-line-through">N</span>1,500 weekly repayment plan <strong>(<span class="double-line-through">N</span>100,000)</strong></small>
                        <small v-if="fields.system == 'TV System'"><span class="double-line-through">N</span>20,000 initial payment, <span class="double-line-through">N</span>7,500 monthly repayment plan <strong>(<span class="double-line-through">N</span>238,500)</strong></small>
                        <small v-if="fields.system == 'Basic System - One Off'">Total payment of <strong><span class="double-line-through">N</span>75,000</strong> </small>
                        <small v-if="fields.system == 'Fan System - One Off'">Total payment of <strong><span class="double-line-through">N</span>93,000</strong> </small>
                        <small v-if="fields.system == 'TV System - One Off'">Total payment of <strong><span class="double-line-through">N</span> 218,500</strong></small>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <select
                          name=""
                          id="cardState"
                          class="form-control form-control-flush"
                          v-model="fields.state"
                          @change="updateLGA"
                        >
                          <option value="" disabled>Select an option</option>
                          <option
                            v-for="state in states"
                            :value="state.alias"
                            :key="state.alias"
                          >
                            {{ state.state }}
                          </option>
                        </select>
                        <label for="cardSystem"
                          >Select Current State
                          <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-floating">
                        <select
                          name=""
                          id="cardLGA"
                          class="form-control form-control-flush"
                          v-model="fields.lga"
                        >
                          <option value="" disabled>Select an option</option>
                          <option
                            v-for="lga in selectedLGA"
                            :value="lga"
                            :key="lga"
                          >
                            {{ lga }}
                          </option>
                        </select>
                        <label for="cardSystem"
                          >Select LGA <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center mb-2">
                    <div class="col">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control form-control-flush"
                          id="cardOccupation"
                          placeholder="Occupation"
                          v-model="fields.occupation"
                        />
                        <label for="cardSystem"
                          >Occupation <span style="color: red">*</span></label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center">
                    <div class="col">
                      <div class="form-group">
                        <label for="cardSystem" class="address-label">Address</label>
                        <textarea
                          name=""
                          rows="3"
                          id="cardAddress"
                          v-model="fields.address"
                          placeholder="1 Winners way"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center">
                    <div class="col">
                      <div class="form-group">
                        <div class="form-check form-switch d-flex align-items-center">
                          <input class="form-check-input" type="checkbox" style="margin-right: 15px" v-model="accept"> By clicking on the button, you agree to our &nbsp;<router-link to="/terms-and-conditions" target="_blank">Terms and Conditions</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-3">
                    <div class="col">
                      <button
                        class="btn w-100 btn-sm btn-success lift"
                        :disabled="loading"
                        type="submit"
                      >
                        {{ loading ? "Loading..." : "Submit" }}
                      </button>
                    </div>
                  </div>
                </form>
                <div v-else>
                  <img
                    src="assets/img/success.svg"
                    width="50%"
                    class="mx-auto d-block mb-5"
                  />
                  <p class="fs-md text-center text-muted mb-6">
                    Your interest has been recorded successfully, someone from
                    our customer support team will reach out to you shortly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Faqs />

    <!-- SHAPE -->
    <div class="position-relative">
      <div class="shape shape-bottom shape-fluid-x text-gray-200">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Brands from "@/components/Brands.vue";
import Faqs from "@/components/Faqs.vue";
import GeneralService from "@/services/GeneralService";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    Brands,
    Faqs,
  },
  computed: {
    ...mapState(["loading"]),
    selectedLGA() {
      let results = this.states.find(
        (state) => state.alias === this.fields.state
      );
      return results ? this.sortBy(results.lgas) : [];
    },
    package_image() {
      if (this.fields.system.includes("Basic System")) {
        return 'img/packages/light_system.png'
      } else if (this.fields.system.includes("Fan System")) {
        return 'img/packages/fan_system.png'
      } else if (this.fields.system.includes("TV System")) {
        return 'img/packages/tv_system.png'
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      success: false,
      states: this.sortBy(this.$globalFunc.locations(), 'name'),
      fields: {
        system: "",
        state: "",
        lga: "",
        payment_plan: "",
      },
      accept: false
    };
  },
  methods: {
    sortBy(array, type = null) {
      if (type) {
        return array.sort((a, b) => a.state < b.state ? -1 : (a.name > b.name ? 1 : 0))
      }
      return array.sort((a, b) => a < b)
    },
    sendRequest() {
      if (this.validateData()) {
        this.$store.dispatch("setLoading", true);
        GeneralService.indicateRequest(this.fields)
          .then((result) => {
            if (result.status) {
              this.success = result.status;
              this.fields.system = "";
              this.showAlert("Successful", result.message, "success");
            }
          })
          .catch((err) => {
            if (err.response === undefined) {
              this.showAlert(
                "Error occured",
                "Oops! took long to get a response",
                "warning"
              );
            } else {
              this.showAlert(
                err.response.data.status == false
                  ? "Error occured"
                  : "Server error",
                err.response.data.status == false
                  ? err.response.data.message
                  : "Internal server error",
                "warning"
              );
            }
          })
          .finally(() => {
            this.$store.dispatch("setLoading", false);
          });
      }
    },
    validateData() {
      if (this.fields.fullname) {
        if (this.fields.phone) {
          if (this.fields.system) {
            if (this.fields.state) {
              if (this.fields.lga) {
                if (this.fields.occupation) {
                  if (this.accept) {
                    return true
                  } else {
                    this.showAlert("Validation error", "You must accept our terms and conditions", "warning");
                  }
                } else {
                  this.showAlert(
                    "Validation error",
                    "Provide your occupation",
                    "warning"
                  );
                }
              } else {
                this.showAlert(
                  "Validation error",
                  "Select your lga state",
                  "warning"
                );
              }
            } else {
              this.showAlert(
                "Validation error",
                "Select your current state",
                "warning"
              );
            }
          } else {
            this.showAlert(
              "Validation error",
              "Select your choice of system",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Phone number is mandatory",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Kindly provide your full name",
          "warning"
        );
      }
    },
    updateLGA() {},
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style scoped>
.text-green {
  color: #05895a;
}
.address-label {
  font-size: 1rem;
  /* color: #869ab8; */
}
.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-bottom: .125rem;
  padding-top: 1.625rem;
  font-size: 16px;
}
.form-control {
  font-size: 16px;
}
.shadow-light-lg {
  box-shadow: 0 0.5rem 0.7rem rgb(5 137 90 / 58%) !important;
}
small {
  font-size: 14px;
  letter-spacing: 0px;
}
.double-line-through {
  text-decoration-line: line-through; 
  text-decoration-style: double;
}
.alert.alert-success {
  background-color: #42ba96;
  border-color: #42ba96;
  color: #fff;
  padding: 0.2rem 0;
  border-radius: 0px;
}
span.text-primary {
  color: #05895a !important;
  font-weight: bold;
}
.bg-colored {
  background: linear-gradient(to bottom, #fed529 70%, #05895a 120%);
  height: 100vh;
}
.text-white {
  color: #fff !important;
}
.form-floating>label {
  color: #0c0c0c;
}
.form-switch {
  font-size: 13px
}
.form-check-input:checked {
  background-color: #42ba96;
  border-color: #42ba96;
}
</style>