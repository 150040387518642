<template>
  <!-- BRANDS -->
  <section class="py-6 py-md-8 border-bottom">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <!-- Brand -->
          <div class="img-fluid text-gray-600 mb-2 mb-md-0">
            <img src="assets/img/partners/partner1.png" alt="Asolar partner" width="100%">
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <!-- Brand -->
          <div class="img-fluid text-gray-600 mb-2 mb-md-0">
            <img src="assets/img/partners/partner2.png" alt="Asolar partner" width="100%">
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <!-- Brand -->
          <div class="img-fluid text-gray-600 mb-2 mb-md-0">
            <img src="assets/img/partners/partner3.png" alt="Asolar partner" width="100%">
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <!-- Brand -->
          <div class="img-fluid text-gray-600 mb-2 mb-md-0">
            <img src="assets/img/partners/partner4.png" alt="Asolar partner" width="100%">
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <!-- Brand -->
          <div class="img-fluid text-gray-600 mb-2 mb-md-0">
            <img src="assets/img/partners/partner5.png" alt="Asolar partner" width="100%">
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-2 mb-4 mb-md-0">
          <!-- Brand -->
          <div class="img-fluid text-gray-600 mb-2 mb-md-0">
            <img src="assets/img/partners/partner6.png" alt="Asolar partner" width="100%">
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
</template>
<script>
export default {
    name: "Brands"
};
</script>
<style scoped>
</style>